<template>
  <div class="card">
    <div>
      <van-search @change="reload" v-model="keyword" show-action placeholder="请输入搜索名称" />
    </div>
    <van-tabs v-model:active="active" @click="toggle" sticky class="home-tab">
      <van-tab :title="item.title" :name="item.type" v-for="(item,ins) in tabData" :key="ins">
        <!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          :immediate-check="false"
          :offset="10"
          finished-text="我是有底线的"
          @load="onLoadList"
          style="padding:15px 15px 50px 15px;"
        >
          <div
            v-for="(item,ins) in cardList"
            :key="ins"
            class="goods-item"
            @click="ShopDetails(item.oid)"
          >
            <div class="left-dis">
              <img :src="item.image" alt class="goods-image" />
            </div>
            <div class="rig-rig">
              <div class="name">{{item.name}}</div>
              <div class="scoreSell">
                <p style="display: flex;align-items: center;">
                  评分:
                  <van-rate
                    v-model.number="item.score"
                    allow-half
                    void-icon="star"
                    void-color="#eee"
                    size="13px"
                    disabled
                    color="#FC6228"
                  />
                  {{item.score}}
                </p>
                <p>月售{{item.sales}}</p>
              </div>
              <div class="addDis">
                <p class="address">{{item.address}}</p>
                <p v-if="item.distance < 1000">{{item.distance}}m</p>
                <p v-else>{{(item.distance/1000).toFixed(2)}}km</p>
              </div>
            </div>
          </div>
          <!-- <div class="goods-item" @click="goDetail()">
											<div class="left-dis">
												<img src="../../assets/wx.jpg" alt="" class="goods-image">
											</div>
											<div class="rig-rig">
												<div class="name">饭先生.锡纸饭</div>
												<div class="scoreSell">
													<van-rate v-model="score" allow-half void-icon="star" void-color="#eee" size="13px"
														color="#FC6228" />
													<p>月售:19万</p>
												</div>
												<div class="addDis">
													<p>郑州金水区东风南路149号</p>
													<p>98m</p>
												</div>
											</div>
										</div>
										<div class="goods-item" @click="hotelDetails()">
											<div class="left-dis">
												<img src="../../assets/gj.jpg" alt="" class="goods-image">
											</div>
											<div class="rig-rig">
												<div class="name">酒店</div>
												<div class="scoreSell">
													<van-rate v-model="score" allow-half void-icon="star" void-color="#eee" size="13px"
														color="#FC6228" />
													<p>月售:19万</p>
												</div>
												<div class="addDis">
													<p>郑州金水区东风南路149号</p>
													<p>98m</p>
												</div>
											</div>
          </div>-->
        </van-list>
        <!-- </van-pull-refresh> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import * as shop from "../../api/shop.js";
export default {
  data() {
    return {
      score: 4,
      searchOrder: "",
      active: 0,
      list: [],
      loading: false,
      finished: false,
      tabData: [
        {
          title: "全部",
          type: "all",
        },
        {
          title: "商超",
          type: 0,
        },
        {
          title: "美食",
          type: 1,
        },
        {
          title: "旅游",
          type: 2,
        },
        {
          title: "酒店",
          type: 3,
        },
      ],
      cardList: [],
      page: 1,
      limit: 10,
      keyword: "",
      lng: 113.6496429,
      lat: 34.7566109,
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      orderStatus: false,
    };
  },
  mounted() {
    this.lists();
  },
  computed: {
    location: function () {
      return this.$store.state.app.latAndLng;
    },
  },
  watch: {
    // meters: function(val) {
    // 	this.kilometers = val / 1000;
    // 	this.meters = val;
    // }
  },
  methods: {
    reload() {
      this.lists();
    },
    toggle(name) {
      console.log(name);
      this.active = name;
      this.page = 1;
      this.cardList = [];
      this.lists();
    },
    // goDetail(i) {
    // 	this.$router.push({
    // 		path: '/h5/worksDetail',
    // 		query: {
    // 			id: i.id
    // 		}
    // 	})
    // },
    goDetail() {
      this.$router.push({
        path: "/h5/foodDetail",
      });
    },
    hotelDetails() {
      this.$router.push({
        path: "/h5/hotelDetails",
      });
    },
    ShopDetails(oid) {
      console.log(oid);
      this.$router.push({
        path: "/h5/shopList",
        query: {
          oid: oid,
        },
      });
    },
    lists() {
      console.log(this.location);
      this.upFinished = true;
      var that = this;
      that.orderStatus = false;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {};
      params.type = this.active;
      params.page = this.page;
      params.limit = this.limit;
      params.lng = this.location.longitude;
      params.lat = this.location.latitude;
      params.keyword = this.keyword;
      shop
        .getStore(params)
        .then((res) => {
          console.log(res);

          const rows = res.data.list;
          // this.cardList = res.data.list
          if (rows.length > 0 && rows.length == this.limit) {
            this.isUpLoading = true;
            this.upFinished = false;
            // 处理数据
            if (this.page === 1) {
              this.cardList = rows;
            } else {
              this.cardList = this.cardList.concat(rows);
            }
          } else {
            this.isUpLoading = false;
            this.upFinished = true;
            setTimeout(function () {
              that.orderStatus = true;
            }, 1000);
          }

          this.$toast.clear();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.lists();
    },
  },
};
</script>

<style scoped="scoped" lang="less">
p {
  margin: 0;
  padding: 0;
}

.card {
  .home-tab {
    ::v-deep .van-tabs__line {
      width: 30px;
      background-color: #fb6228 !important;
      bottom: 22px;
    }
  }

  .goods-item {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 10px 15px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 10px;
    // padding: 5px 15px;
    justify-content: flex-start;

    .left-dis {
      font-size: 14px;
      // width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      p {
        margin: 0;
        padding: 0;

        span {
          color: #888;
          font-size: 13px;
          margin-left: 5px;
        }
      }

      .goods-image {
        width: 100px;
        height: 80px;
        border-radius: 5px;
        object-fit: cover;
        flex-shrink: 0;
      }
    }

    .rig-rig {
      display: flex;
      flex-direction: column;
      width: calc(100% - 115px);
      height: 100%;
      margin-left: 15px;

      .name {
        font-size: 0.2rem;
      }

      .scoreSell {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 0.15rem;
        margin-top: 10px;
      }

      .addDis {
        display: flex;
        justify-content: space-between;
        font-size: 0.15rem;
        color: #999999;
        margin-top: 10px;

        .address {
          width: 130px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
