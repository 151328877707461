import request from '@/utils/request'

// 文旅酒店首页接口
export function getStore(params) {
    return request({
        url: '/store/lst',
        method: 'get',
        isLogin: false,
		params
    })
}